h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li,
strong,
label,
span,
td,
button,
input,
select,
mat-error,
body,
textarea {
  // -moz-osx-font-smoothing: grayscale;
  //  -webkit-font-smoothing: antialiased;
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.4rem;
  font-display: swap;
  white-space: pre-line;
}

.marcellus {
  font-family: "Marcellus", serif !important;
}

.marcellus-sc {
  font-family: "Marcellus SC", serif !important;
}

.ql-snow .ql-picker-options .ql-picker-item {
  font-family: #{attr(data-value)};
  &::before {
    content: attr(data-value) !important;
  }
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: attr(data-value) !important;
}

$text-editor-fonts: "Manrope", "serif", "sansserif", "monospace", "Marcellus";

@each $text-editor-font in $text-editor-fonts {
  .ql-font-#{$text-editor-font} {
    font-family: $text-editor-font;
  }
}
