.center {
  @apply left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
}

.hide-scrollbar {
  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
    background: transparent;
  }
}

.swipe-pagination-fix {
  .swiper-slide {
    flex: 0 1 auto;
    display: flex;
    height: auto;
    width: 90vw;
  }
}

.widget-title {
  @apply text-black font-medium text-16;
}

.widget-textarea {
  @apply form-input rounded-md text-charcoal-dark bg-[#fff];
  @apply border border-gray-300 ring-0;
  @apply focus:border-black focus:ring-black focus:ring-1;
  outline: transparent !important;
  min-height: 32px;
  font-size: 1.6rem;
  box-sizing: content-box;
}


.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
