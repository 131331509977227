.ngx-gallery-image-text {
  background: rgba(0, 0, 0, 0) !important;
  text-align: end !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  font-size: 1.8rem !important;
  // font-family: $font-family;

  @media screen and (max-width: 1023px) {
    top: 0 !important;
  }
}
