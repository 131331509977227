.pac-container {
  position: fixed !important;
  .pac-icon {
    background-image: url("/assets/svg/location_pin.svg") !important;
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;
    background-position: center center;
    padding-bottom: 12px !important;
  }
}
