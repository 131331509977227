.container-height {
  min-height: calc(100vh - 60px);
  overflow-y: scroll;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.sl-box {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 568px) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .sl-box {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 569px) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .sl-box {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 768px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .sl-box {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 992px) {
  .container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .sl-box {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 1200px) {
  .container {
    padding-left: 7.5vw;
    padding-right: 7.5vw;
  }

  .sl-box {
    padding-left: 7.5vw;
    padding-right: 7.5vw;
  }
}

@media (min-width: 1400px) {
  .container {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .sl-box {
    padding-left: calc((100vw - 1280px) / 2);
    padding-right: calc((100vw - 1280px) / 2);
  }
}

// @media (min-width: 2000px) {
//     .container {
//         padding-left: calc((100vw - 1900px) / 2);
//         padding-right: calc((100vw - 1900px) / 2);
//     }

//     .containerPadding {
//         padding-left: calc((100vw - 1900px) / 2);
//         padding-right: calc((100vw - 1900px) / 2);
//     }
// }

.md-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .md-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 992px) {
  .md-container {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 1200px) {
  .md-container {
    max-width: 90%;
  }
}

@media (min-width: 1600px) {
  .md-container {
    max-width: 1536px;
  }
}