html,
body {
  height: 100%;
  font-size: 62.5%;
  scroll-behavior: smooth;
  background-color: white;
}

@import "quill/dist/quill.core.css";
@import "quill/dist/quill.snow.css";
@import "assets/scss/theme.scss";
@import "keen-slider/keen-slider.min.css";
@import "cloudinary-video-player/cld-video-player.min.css";

// https://keen-slider.io/examples

.mat-mdc-select-placeholder {
  color: #565251 !important;
  font-weight: 500 !important;
}

.mdc-notched-outline__notch {
  border-right: none;
}

.mat-mdc-form-field-infix {
  // min-height: 0 !important;
  padding: 8px 0 !important;
}

html {
  --mdc-switch-selected-handle-color: #363c44;
  --mdc-switch-selected-pressed-handle-color: #363c44;
  --mdc-switch-selected-hover-handle-color: #363c44;
  --mdc-switch-selected-focus-handle-color: #363c44;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-selected-track-color: #909397;
  --mdc-switch-selected-hover-track-color: #909397;
  --mdc-switch-selected-pressed-track-color: #909397;
  --mdc-switch-selected-focus-track-color: #909397;
}
