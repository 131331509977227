.form-input:focus,
.form-textarea:focus,
.form-select:focus,
.form-multiselect:focus {
  --tw-ring-color: none;
  --tw-ring-shadow: none;
  border-color: #333;
}

.no-wrapper {
  .mat-form-field-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }

  .mat-form-field-subscript-wrapper {
    display: none !important;
  }
}

.input {
  @apply form-input rounded-lg text-charcoal-dark;
  @apply border-gray-200 bg-gray-50 ring-0;
  @apply focus:border-gray-400 focus:ring-gray-200;
  height: 44px;
  outline: transparent !important;
  font-size: 1.6rem;
}
