.btn {
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 700;
  padding: 0.4rem 1.5rem;
  white-space: nowrap;
  line-height: 1.75;
  cursor: pointer;
  color: white;
  border-radius: 3px;
  text-transform: none;
  transition: all 0.2s ease-in-out;
  background-color: var(--primary-color);

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s;
    overflow: visible;
    border-radius: 3px;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.8;
    transform: none;
    cursor: not-allowed;
  }
}

.btn-round {
  border-radius: 9999px;

  &:hover {
    border-radius: 9999px;
  }
}

.action-btn {
  @apply border border-gray-200 cursor-pointer bg-gray-50 py-2 px-4 rounded-lg text-14 text-gray-800 text-center;
  @apply hover:bg-gray-100;
}
