.dialog-remove-padding {
  .mat-dialog-container {
    padding: 0px !important;

    .mat-dialog-title {
      background-color: #faf9fe;
      padding: 23px 23px 17px;
    }

    .mat-dialog-content {
      margin: 0;
    }

    .mat-dialog-actions {
      padding: 0px 16px 10px;
      background-color: #faf9fe;
    }
  }
}

mat-dialog-container {
  padding: 0px !important;
}

.image-viewer-dialog {
  .mat-dialog-container {
    background-color: black;
    max-width: 100vw;
    max-height: 100vh;
    border-radius: 0;
  }
}

.mat-snack-bar-container {
  background: white !important;
}
